<template>
  <div id="classrooms" class="container mx-auto">
    <header
      id="header-section"
      class="header-classroom inline-block w-full my-12 text-center relative"
    >
      <h1 class="text-white text-center text-4xl font-bold">Tus clases</h1>
      <div class="crud-settings" v-if="user.loggedIn">
        <div class="item_crud-settings add" @click="openNew()" v-if="!loading">
          <svg
            viewBox="0 0 24 24"
            height="32"
            width="32"
            role="img"
            aria-hidden="true"
          >
            <path
              fill="#ffffff"
              d="M17,3A2,2 0 0,1 19,5V21L12,18L5,21V5C5,3.89 5.9,3 7,3H17M11,7V9H9V11H11V13H13V11H15V9H13V7H11Z"
            />
          </svg>
        </div>
      </div>
    </header>
    <loading v-if="loading"></loading>
    <transition-group
      name="list"
      tag="main"
      class="list-classroom"
      v-if="classrooms"
      v-on:enter="enter"
      v-on:before-enter="beforeEnter"
    >
      <div
        class="item-classroom mb-10"
        v-for="(classroom, index) in classrooms"
        :key="classroom.id"
      >
        <classroom-card
          :classroom="classroom"
          :position="index + 1"
        ></classroom-card>
      </div>
    </transition-group>
    <div
      class="item-classroom mb-10"
      @click="openNew()"
      v-if="!classrooms.length && !loading"
    >
      <classroom-card :classroom="null"></classroom-card>
    </div>
    <modal
      name="newClassroom"
      :clickToClose="false"
      height="auto"
      class="form-modal"
      v-if="user.loggedIn"
      :scrollable="true"
    >
      <h1 class="title-modal">Añadir Clase</h1>
      <input
        type="text"
        v-model="newName"
        placeholder="Introduce el nombre de la Clase"
      />
      <button @click="newClase" class="w-1-2">
        <span class="text-button">Crear</span>
      </button>
      <button @click="closeNew" class="w-1-2">
        <span class="text-button">Cancelar</span>
      </button>
    </modal>
  </div>
</template>

<script>
// @ is an alias to /src
import data from "../services/data";
import classroomCard from "../components/classroomCard";
import Velocity from "velocity-animate";
import { mapGetters, mapState } from "vuex";
import Loading from "../components/Loading.vue";

export default {
  name: "classrooms",
  components: { classroomCard, Loading },

  data() {
    return {
      loading: true,
      newName: "",
      newMaxPoints: 10,
    };
  },
  computed: {
    ...mapState(["classroomCode", "classrooms", "user"]),
    ...mapGetters(["userCan"]),
  },
  methods: {
    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transform = "translate-y(15px)";
    },
    enter: function (el) {
      var delay = el.dataset.index * 100;
      setTimeout(function () {
        Velocity(
          el,
          {
            opacity: 1,
            translateY: 0,
          },
          "easeOut"
        );
      }, delay);
    },
    openNew() {
      if (this.userCan.maxClassrooms > this.classrooms.length)
        this.$modal.show("newClassroom");
      else this.$modal.show("goPremium");
    },

    closeNew() {
      this.$modal.hide("newClassroom");
    },

    newClase() {
      var el = this;
      this.$store.dispatch("searchNewCode").then(function (code) {
        data
          .addClassRoom(code, {
            name: el.newName,
            max_points: el.newMaxPoints,
            user: el.user.data.id,
          })
          .then((response) => {
            if (response && response.errorMsg) {
              //console.error(response.errorMsg);
            } else {
              el.getClassrooms();
              el.goToClassroom(code);
            }
          });
      });
    },
    goToClassroom(classroomId) {
      data.getClassRoom(classroomId).then((response) => {
        if (response && response.errorMsg) {
          if (this.$store.state.classroomCode)
            this.$store.commit("setClassroomCode", null);
          else this.error = true;
        } else {
          this.$store.commit("setClassroomCode", classroomId);
          this.$router.push({ name: "ranking" });
        }
      });
    },
    getClassrooms() {
      data.getClassRooms(this.user.data.id).then((response) => {
        if (response && response.errorMsg) {
          this.$router.push({ name: "access" });
        } else {
          this.loading = false;
          this.$store.commit("setClassrooms", response);
        }
      });
    },
  },
  created() {
    if (this.user.loggedIn) {
      if (this.classrooms.length) this.loading = false;
      this.$store.commit("setClassroom", null);
      this.$store.commit("setClassroomStudents", null);
      this.$store.commit("setClassroomMarkers", null);
      this.getClassrooms();
      data.refreshSubscription(this.user.data.id);
    } else {
      this.$router.push({ name: "access" });
    }
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
