<template>
  <div id="profile" class="container mx-auto">
    <header
      id="header-section"
      class="header-ranking inline-block w-full mt-12 mb-1 text-center relative"
    >
      <h1 class="text-white text-center text-3xl font-bold">Perfil</h1>
      <h2
        class="
          text-white text-center text-2xl
          font-thin
          uppercase
          tracking-widest
        "
      >
        Información de tu cuenta
      </h2>
    </header>

    <div class="profile-data flex flex-col items-center mt-10">
      <div class="flex element">
        <b class="mr-2">Nombre:</b> {{ user.data.name | displayName }}
      </div>
      <div class="flex element">
        <b class="mr-2">Email:</b> {{ user.data.email }}
      </div>
      <div class="flex element mt-1 mb-4">
        <b class="mr-2">Membresía:</b>
        <span class="capitalize"
          >{{ user.data.role }}
          <template
            v-if="
              user.data.subscription &&
              user.data.subscription.status === 'trialing'
            "
            >(Prueba)</template
          ></span
        >
      </div>
      <button class="max-w-xs openChangePassword" @click="openEditProfile">
        <span class="text-button">Editar Perfil</span>
      </button>
      <button
        class="max-w-xs openChangePassword mb-4"
        @click="openChangePassword"
      >
        <span class="text-button">Cambiar contraseña</span>
      </button>

      <button
        class="max-w-xs openChangePassword"
        @click="openGoPremium"
        v-if="user.data.role === 'free'"
      >
        <span class="text-button">Pásate a Premium</span>
      </button>

      <button
        class="max-w-xs openChangePassword"
        v-else-if="user.data.subscription"
        @click="goCustomerPortal"
      >
        Gestionar Membresía
      </button>
      <p class="text-xs text-center mt-5 text-green-500" v-if="passwordUpdated">
        Contraseña actualizada
      </p>
      <!--div @click="openLopd" class="btn-lopd mt-5">Política de Privacidad</div-->
      <a
        href="https://advservices-my.sharepoint.com/:w:/g/personal/formacion_advservices_es/Eb8oEoNYLkxCtqkE-TlAIlgBwZmnqBqKoMRJvRQLFdWMbQ"
        class="btn-lopd mt-5 text-sm"
        target="_blank"
      >
        Política de Privacidad
      </a>

      <a
        href="https://advservices-my.sharepoint.com/:w:/g/personal/formacion_advservices_es/EavFu3n4Ah9EhiW8I57m2VQBgZez6f8yymvTKOkfQX8iCA"
        class="btn-lopd mt-5 text-sm"
        target="_blank"
        >Términos y condiciones</a
      >
    </div>
    <modal
      name="editProfile"
      :clickToClose="false"
      height="auto"
      class="form-modal"
      :scrollable="true"
    >
      <h1 class="title-modal">Editar Perfil</h1>

      <input
        type="text"
        v-model="editProfileData.displayName"
        placeholder="Introduce tu nombre"
        class="mb-6"
      />
      <div class="changePassword-error" v-if="editProfileError">
        {{ editProfileError }}
      </div>
      <button @click="editProfile" class="w-1-2">
        <span class="text-button">Guardar</span>
      </button>
      <button @click="closeEditProfile" class="w-1-2">
        <span class="text-button">Cancelar</span>
      </button>
    </modal>
    <modal
      name="changePassword"
      :clickToClose="false"
      height="auto"
      class="form-modal"
      :scrollable="true"
    >
      <h1 class="title-modal">Cambiar contraseña</h1>

      <input
        type="password"
        v-model="actualPassword"
        placeholder="Introduce tu Password"
        class="mb-6"
      />
      <input
        type="password"
        v-model="newPassword"
        placeholder="Nuevo Password"
        class="mb-2"
      />
      <input
        type="password"
        v-model="newnewPassword"
        placeholder="Repite Nuevo Password"
        class="mb-2"
      />
      <div class="changePassword-error" v-if="changePasswordError">
        {{ changePasswordError }}
      </div>
      <button @click="changePassword" class="w-1-2">
        <span class="text-button">Guardar</span>
      </button>
      <button @click="closeChangePassword" class="w-1-2">
        <span class="text-button">Cancelar</span>
      </button>
    </modal>
    <lopd></lopd>
    <premium-modal></premium-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import data from "../services/data";
import Lopd from "../components/lopd.vue";
import premiumModal from "../components/goPremiumProfile.vue";

export default {
  name: "origin-markers",
  components: { Lopd, premiumModal },

  data() {
    return {
      actualPassword: "",
      newPassword: "",
      newnewPassword: "",
      changePasswordError: false,
      passwordUpdated: false,
      editProfileData: {
        displayName: "",
      },
      editProfileError: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    openLopd() {
      this.$modal.show("lopd");
    },
    openChangePassword() {
      this.$modal.show("changePassword");
    },
    openGoPremium() {
      //data.startSubscription(this.user.data.id).then(() => {
      //console.log("StartSubscription");
      //});
      this.$modal.show("goPremiumProfile");
    },

    openEditProfile() {
      this.editProfileData.displayName = this.user.data.name;
      this.$modal.show("editProfile");
    },

    closeChangePassword() {
      this.$modal.hide("changePassword");
    },
    closeEditProfile() {
      this.$modal.hide("editProfile");
    },

    changePassword() {
      if (
        this.newPassword.length < 6 ||
        this.newPassword != this.newnewPassword
      ) {
        this.changePasswordError =
          "Las contraseñas no coinciden o tienen menos de 6 carácteres.";
      } else
        data
          .changePassword(this.actualPassword, this.newPassword)
          .then(() => {
            this.closeChangePassword();
            this.changePasswordError = false;
            this.passwordUpdated = true;
          })
          .catch((errorMesssage) => {
            this.changePasswordError = errorMesssage;
          });
    },

    editProfile() {
      if (
        !this.editProfileData.displayName ||
        this.editProfileData.displayName.length < 3
      )
        this.editProfileError = "El nombre introducido no es correcto.";
      else
        data
          .updateProfile(this.editProfileData)
          .then(() => {
            this.closeEditProfile();
            this.editProfileError = false;
          })
          .catch(() => {
            this.editProfileError = "Ha ocurrido un error.";
          });
    },
    goCustomerPortal() {
      data.redirectCustomerPortal();
    },
  },
  created() {},
  mounted() {
    data.refreshSubscription(this.user.data.id);
  },

  filters: {
    displayName(value) {
      if (!value || !value.length) return "Sin definir";
      return value;
    },
  },
};
</script>

<style lang="scss">
#profile {
  button.openChangePassword {
    background: #009688;
    width: 100%;
    padding: 15px;
    margin-top: 10px;
    background-image: linear-gradient(to right, #173e3b, #009688);
    transform: skew(-5deg);
    text-transform: uppercase;

    span {
      transform: skew(5deg);
    }
  }

  .changePassword-error {
    margin-top: 10px;
    background: #be4242;
    color: white;
    padding: 10px;
    text-align: center;
  }

  .btn-lopd {
    margin-top: 30px;
    border-bottom: 1px solid #fff;
    padding: 7.5px;
  }
}
</style>
