<template>
  <vue-pull-refresh :on-refresh="refresh" :config="refreshConfig">
    <div id="ranking" class="container mx-auto">
      <header
        id="header-section"
        class="header-ranking relative inline-block w-full my-12 text-center"
      >
        <h1 class="text-white text-center text-4xl font-bold">TOP Ranking</h1>
        <h2
          class="
            text-white text-center text-2xl
            font-thin
            uppercase
            tracking-widest
          "
        >
          {{ classroom.name }}
        </h2>

        <div class="crud-settings" v-if="user.loggedIn">
          <div class="item_crud-settings edit" @click="openEditClassroom()">
            <svg
              viewBox="0 0 24 24"
              height="32"
              width="32"
              role="img"
              aria-hidden="true"
            >
              <path
                fill="#ffffff"
                d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
              />
            </svg>
          </div>
          <div class="item_crud-settings" @click="qduplicate()">
            <svg
              viewBox="0 0 24 24"
              height="32"
              width="32"
              role="img"
              aria-hidden="true"
            >
              <path
                fill="#ffffff"
                d="M11,17H4A2,2 0 0,1 2,15V3A2,2 0 0,1 4,1H16V3H4V15H11V13L15,16L11,19V17M19,21V7H8V13H6V7A2,2 0 0,1 8,5H19A2,2 0 0,1 21,7V21A2,2 0 0,1 19,23H8A2,2 0 0,1 6,21V19H8V21H19Z"
              />
            </svg>
          </div>
          <div class="item_crud-settings" @click="qclean">
            <svg viewBox="0 0 1000 1000" height="32" width="32">
              <g>
                <path
                  fill="#ffffff"
                  d="M599,755.5c-5.6,22.3-34.1,71.1-79.5,136.9c12.4,2.9,24.3,6.1,36.4,8.3c22,4,44.1,7.1,66.1,11.1c7.2,1.3,10.9-1.3,14.7-7c44.2-65.8,79.9-136.1,109.8-209.3c11.7-28.7,21.7-58,32.5-87c1.1-3,2.2-5.9,3.5-9.5c23.7,8.2,46.9,16.2,71.1,24.5c-4.3,12.7-8.3,24.8-12.6,36.7c-36.4,101.1-81.7,197.8-142.1,287c-5,7.4-10.7,14.4-15.6,21.8c-11.4,17.1-27.3,22.4-47.2,20.5c-76.8-7.3-151.3-24.8-223.4-51.9c-131.2-49.4-244.2-125-333.7-234c-10.7-13.1-13.8-27.5-6.8-42.9c6.8-14.8,19.8-21.5,35.6-21.7c68.2-0.9,133.9-14.6,197.5-38.6c57.5-21.7,109.9-51.9,150.2-99.5c11.7-13.8,21.2-29.4,32.3-45c20.8,11.7,42.2,23.8,65.7,37c-12.2,17.7-23,35.3-35.8,51.4c-31.2,39.5-70.5,69.4-114.2,93.7c-65.8,36.6-136.9,57.7-210.9,69.7c-1.5,0.2-2.9,0.5-6.5,1.2c10.6,9.7,19.5,18.8,29.3,26.7c18.4,14.9,37.1,29.4,56.2,43.4c3.7,2.7,10.2,3.9,14.8,2.8c38-8.6,72.8-23.8,102-50.8c-2.5,13.3-26.5,47-60.2,84.6c21.2,10.5,42.5,19.8,62.5,31.5c25.8,15.2,50.5,11,75.8,0.1c42.8-18.5,79.5-46.1,114.6-76.2C586.8,766.4,592.3,761.4,599,755.5L599,755.5z M876.3,560.4C756.2,507,637,453.9,516.9,400.5c9.9-23.1,25.5-40.6,46.6-53c35.2-20.7,72-23.1,109.3-6.8c48,20.9,95.9,42,143.5,64c55.8,25.9,82.1,84,65.1,141.7C880,550.9,878.2,555.2,876.3,560.4L876.3,560.4z M823,351.1c-37.8-16.8-75.2-33.5-112.5-50.1c-0.8-0.4-1.4-1.2-2.4-2.2c1.4-2.8,2.7-5.7,4.2-8.4c46.7-87.7,93.5-175.3,140.3-263c7.8-14.7,17-19.1,33.8-16.6c15.5,2.3,28.6,8.9,38.7,21c6.6,8,8.3,16.7,4.6,26.8c-34.9,96-69.6,192.1-104.3,288.2C824.8,347.9,824.1,348.9,823,351.1L823,351.1z"
                />
              </g>
            </svg>
          </div>
          <div class="item_crud-settings" @click="qdelete">
            <svg
              viewBox="0 0 24 24"
              height="32"
              width="32"
              role="img"
              aria-hidden="true"
            >
              <path
                fill="currentColor"
                d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
              />
            </svg>
          </div>
          <div
            class="item_crud-settings add"
            @click="openAddStudent()"
            v-if="!loading"
          >
            <svg
              viewBox="0 0 24 24"
              height="32"
              width="32"
              role="img"
              aria-hidden="true"
            >
              <path
                fill="#ffffff"
                d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
              />
            </svg>
          </div>
        </div>
      </header>
      <loading v-if="loading"></loading>
      <transition-group
        name="list"
        tag="main"
        class="list-ranking"
        v-if="rankedClassroomStudents"
        v-on:enter="enter"
        v-on:before-enter="beforeEnter"
      >
        <div
          id="item-student"
          class="item-ranking mb-10"
          v-for="(student, position) in rankedClassroomStudents"
          :key="student.id"
          :data-index="position"
        >
          <student-card
            :student="student"
            :position="position + 1"
            :markers="classroomMarkers"
            :points="student.points"
            :maxpoints="classroom.max_points"
          ></student-card>
        </div>
      </transition-group>

      <div
        id="item-student-new"
        class="item-ranking mb-10"
        @click="openAddStudent()"
        v-if="user.loggedIn && !loading"
      >
        <student-card :student="null" :position="null"></student-card>
      </div>

      <modal
        name="editClassroom"
        :clickToClose="false"
        height="auto"
        class="form-modal"
        :scrollable="true"
      >
        <h1 class="title-modal">Editar Clase</h1>
        <div class="input-modal text-center">
          <span class="label">Nombre:</span>
          <input
            type="text"
            v-model="editClassroomForm.name"
            placeholder="Introduce el nombre del alumno/a"
          />
        </div>
        <div class="input-modal text-center">
          <span class="label">Puntuación Máxima:</span>
          <number-input
            v-model="editClassroomForm.max_points"
            :min="1"
            :max="1000"
            inline
            controls
            ><span class="label">Puntuación Máxima:</span></number-input
          >
        </div>
        <button @click="editClass" class="w-1-2 save-modal">
          <span class="text-button">Guardar</span>
        </button>
        <button @click="closeModal" class="w-1-2 close-modal">
          <span class="text-button">Cancelar</span>
        </button>
      </modal>

      <modal
        name="addStudent"
        :clickToClose="false"
        height="auto"
        class="form-modal"
        :scrollable="true"
      >
        <h1 class="title-modal">Añadir Alumno/a</h1>
        <input
          type="text"
          v-model="newStudentForm.name"
          placeholder="Introduce el nombre del alumno/a"
        />
        <button @click="newStudent" class="w-1-2 save-modal">
          <span class="text-button">Añadir</span>
        </button>
        <button @click="closeModal" class="w-1-2 close-modal">
          <span class="text-button">Cancelar</span>
        </button>
      </modal>
    </div>
  </vue-pull-refresh>
</template>

<script>
// @ is an alias to /src
import data from "../services/data";
import helpers from "../services/helpers";
import studentCard from "../components/studentCard";
import Velocity from "velocity-animate";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import VuePullRefresh from "vue-awesome-pull-refresh";
import Loading from "../components/Loading.vue";

export default {
  name: "ranking",
  components: { studentCard, VuePullRefresh, Loading },

  data() {
    return {
      loading: true,
      editClassroomForm: {
        name: null,
        max_points: 0,
      },
      newStudentForm: {
        name: null,
      },
      refreshConfig: {
        errorLabel: "Ha habido un error",
        startLabel: "Actualizar datos",
        readyLabel: "Suelta para actualizar",
        loadingLabel: "Actualizando datos",
        pullDownHeight: 40,
      },
    };
  },
  computed: {
    ...mapState([
      "classroomCode",
      "classroomStudents",
      "classroomMarkers",
      "classroom",
      "classrooms",
      "user",
    ]),
    ...mapGetters(["rankedClassroomStudents", "userCan"]),
  },
  methods: {
    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transform = "translate-y(15px)";
    },
    enter: function (el) {
      var delay = el.dataset.index * 100;
      setTimeout(function () {
        Velocity(
          el,
          {
            opacity: 1,
            translateY: 0,
          },
          "easeOut"
        );
      }, delay);
    },
    openEditClassroom() {
      this.editClassroomForm.name = this.classroom.name;
      this.editClassroomForm.max_points = this.classroom.max_points;
      this.$modal.show("editClassroom");
    },
    openAddStudent() {
      if (this.userCan.maxStudentsByClassroom > this.classroomStudents.length)
        this.$modal.show("addStudent");
      else this.$modal.show("goPremium");
    },
    closeModal() {
      this.$modal.hide("editClassroom");
      this.$modal.hide("addStudent");
    },
    editClass() {
      data
        .editClassRoom({
          id: this.classroom.id,
          name: this.editClassroomForm.name,
          max_points: this.editClassroomForm.max_points,
        })
        .then((response) => {
          this.$modal.hide("editClassroom");
          this.$store.commit("setClassroom", response);
        })
        .catch(() => {});
    },
    qduplicate() {
      if (
        this.userCan.copyData &&
        this.userCan.maxClassrooms > this.classrooms.length
      )
        this.$dialog
          .confirm("¿Estas seguro que quieres duplicar la clase?")
          .then(() => {
            this.duplicateClassroom();
          })
          .catch(function () {});
      else this.$modal.show("goPremium");
    },
    duplicateClassroom() {
      this.$store.dispatch("searchNewCode").then((code) => {
        this.$store.dispatch("duplicateClassroom", code).then(() => {
          this.$store.commit("setClassroomCode", code);
          this.getClassroom();
          this.getClassroomStudents();
          this.getClassroomMarkers();
        });
      });
    },
    qclean() {
      if (this.userCan.clearData)
        this.$dialog
          .confirm(
            "¿Estas seguro que quieres limpiar los datos de los/as alumnos/as?"
          )
          .then(() => {
            this.cleanStudents();
          })
          .catch(function () {});
      else this.$modal.show("goPremium");
    },
    qdelete() {
      this.$dialog
        .confirm("¿Estas seguro que quieres eliminar clase?")
        .then(() => {
          this.deleteClassroom();
        })
        .catch(function () {});
    },
    deleteClassroom() {
      data
        .deleteClassRoom(this.classroom.id)
        .then(() => {
          this.$modal.hide("editClassroom");
          data.getClassRooms(this.user.data.id).then((response) => {
            if (response && response.errorMsg) {
              this.$router.push({ name: "access" });
            } else {
              this.$store.commit("setClassrooms", response);
              this.$router.push({ name: "classrooms" });
            }
          });
        })
        .catch(() => {});
    },
    cleanStudents() {
      data
        .cleanStudents(this.classroom.id)
        .then(() => {
          this.$modal.hide("editClassroom");
          data.getClassRooms(this.user.data.id).then((response) => {
            if (response && response.errorMsg) {
              this.$router.push({ name: "access" });
            } else {
              this.getClassroomStudents();
            }
          });
        })
        .catch(() => {});
    },
    newStudent() {
      // var el = this;
      data
        .addClassroomStudent(this.classroomCode, this.newStudentForm)
        .then(() => {
          this.getClassroomStudents(this.classroomCode);
          this.$modal.hide("addStudent");
        })
        .catch(() => {});
    },
    getClassroom() {
      data.getClassRoom(this.classroomCode).then((response) => {
        if (response && response.errorMsg) {
          this.$router.push({ name: "access" });
        } else {
          this.$store.commit("setClassroom", response);
        }
      });
    },
    getClassroomStudents() {
      data.getClassroomStudents(this.classroomCode).then((response) => {
        if (response && response.errorMsg) {
          this.$router.push({ name: "access" });
        } else {
          this.loading = false;
          this.$store.commit(
            "setClassroomStudents",
            helpers.addTotalPointsToStudents(response)
          );
        }
      });
    },
    getClassroomMarkers() {
      data.getClassroomMarkers(this.classroomCode).then((response) => {
        if (response && response.errorMsg) {
          this.$router.push({ name: "access" });
        } else {
          this.$store.commit("setClassroomMarkers", response);
        }
      });
    },
    refresh() {
      return new Promise((resolve, reject) => {
        data.getClassRoom(this.classroomCode).then((response) => {
          if (response && response.errorMsg) {
            reject();
          } else {
            this.$store.commit("setClassroom", response);
            data.getClassroomStudents(this.classroomCode).then((response) => {
              if (response && response.errorMsg) {
                reject();
              } else {
                this.$store.commit(
                  "setClassroomStudents",
                  helpers.addTotalPointsToStudents(response)
                );
                data
                  .getClassroomMarkers(this.classroomCode)
                  .then((response) => {
                    if (response && response.errorMsg) {
                      reject();
                    } else {
                      this.$store.commit("setClassroomMarkers", response);
                      resolve();
                    }
                  });
              }
            });
          }
        });
      });
    },
  },
  created() {
    if (this.classroomCode) {
      if (!Object.keys(this.classroom).length) this.getClassroom();

      if (!this.classroomStudents.length) this.getClassroomStudents();
      else this.loading = false;

      // if (!this.markers.length)
      this.getClassroomMarkers();
    } else {
      this.$router.push({ name: "access" });
    }
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.v-easy-refresh {
  width: 100vw !important;
  height: 100vh !important;
}
</style>
